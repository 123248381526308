import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuetable from 'vue3-vuetable'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import { loadFonts } from './plugins/webfontloader'

import VuePerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/style.css'
import 'sweetalert2/dist/sweetalert2.min.css'
import Select2 from 'v-select2-component';

loadFonts()


const app = createApp(App);

app.use(router)
app.use(store)
app.use(vuetify)
app.use(Vuetable)
app.use(VueSweetalert2)
app.use(Select2)
app.use(VuePerfectScrollbar)

app.mount('#app');