export const users = (state) => {
    return state.users
}

export const userConnectes = (state) => {
    return state.userConnectes
}

export const userInscrits = (state) => {
    return state.userInscrits
}

export const userCount = (state, getters) => {
    return getters.doneUsers.length
}

export const getUserById = (state) => (id) => {
    return state.users.find(user => user._id === id)
}

export const getUser = (state) => {
    return state.user
}