export const SET_TEMOIGNAGES = (state, temoignages) => {
  state.temoignages = temoignages;
}

export const SET_TEMOIGNAGE = (state, temoignage) => {
  state.temoignage = temoignage;
}

export const SET_ALL_TEMOIGNAGES = (state, temoignages) => {
  state.temoignages.concat(temoignages);
}

export const GET_TEMOIGNAGE = (state, index) => {
  if (index) {
    return state.temoignages.find(temoignage => temoignage.id === index);
  }
}
export const REMOVE_TEMOIGNAGE = (state, index) => {
  let rs = state.temoignages.filter(currentEnseignement => {
    return currentEnseignement.id !== index;
  });

  state.temoignages = [...rs];
}