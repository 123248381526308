import axios from 'axios';

export const getVersets = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('/versets').then((response) => {
        commit('SET_VERSETS', response.data.data);
    })

}

export const postVersetAll = ({ commit }, versets) => {
    return new Promise((resolve, reject) => {
        axios.post(`/versets`, versets)
            .then((response) => {
                const { data, status } = response.data;
                if (status === 200) {
                    if (Array.isArray(data.data))
                        commit("SET_ALL_VERSETS", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const getVerset = async ({ commit }, id) => {
    axios.get('/versets/' + id)
        .then(response => {
            commit('SET_VERSET', response.data.data);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postVerset = ({ commit }, verset) => {
    return new Promise((resolve, reject) => {
        axios.post(`/versets`, verset)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_VERSET", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putVerset = ({ commit }, verset) => {
    return new Promise((resolve, reject) => {
        axios.put(`/versets/${verset.id}`, verset)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_VERSET", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const deleteVerset = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/versets/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("REMOVE_VERSET", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}