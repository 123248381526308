import axios from 'axios';

export const getActions = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('/actions').then((response) => {
        commit('SET_ACTIONS', response.data.data);
    })

}

export const getAction = async ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    axios.get('/actions/' + id)
        .then(response => {
            commit('SET_ACTION', response.data.data[0]);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postAction = ({ commit }, specialite) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.post(`/actions`, specialite)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_ACTION", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('auth')
                } reject(error)
            });
    })
}

export const putAction = ({ commit }, specialite) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.put(`/actions`, specialite)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_ACTION", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                if (error.response.status == 403) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('auth')
                } reject(error)
            });
    })
}

export const deleteAction = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/actions/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 204) {
                    commit("REMOVE_ACTION", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}