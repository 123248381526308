export const prieres = (state) => {
    return state.prieres
}

export const priereCount = (state, getters) => {
    return getters.donePrieres.length
}

export const getPriereById = (state) => (id) => {
    return state.prieres.find(priere => priere._id === id)
}

export const priere = (state) => {
    return state.priere
}