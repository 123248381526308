export const SET_ACTIONS = (state, actions) => {
  state.actions = actions;
}

export const SET_ACTION = (state, action) => {
  state.action = action;
}

export const GET_ACTION = (state, index) => {
  if (index) {
    return state.actions.find(action => action.id === index);
  }
}
export const REMOVE_ACTION = (state, index) => {
  let rs = state.actions.filter(currentAction => {
    return currentAction.id !== index;
  });

  state.actions = [...rs];
}