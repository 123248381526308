// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify(
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  {
    iconfont: 'md',
    theme: {
      primary: '#42A297',
      secondary: '#C3EAEB',
      black: '#1D1D1D',
      white: '#FFFFFF',
      success: '#3cd1c2',
      info:'#ffaa2c',
      error: '#f83e70'
    }
  }
)