export const menus = (state) => {
    return state.menus
}

export const menuCount = (state, getters) => {
    return getters.doneActions.length
}

export const getMenuById = (state) => (id) => {
    return state.menus.find(menu => menu._id === id)
}

export const menu = (state) => {
    return state.menu
}