import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import axios from 'axios';
import dashModule from "../modules/dash";

import authModule from "../modules/auth";

import userModule from "../modules/user";
import priereModule from "../modules/priere";
import enseignementModule from "../modules/enseignement";
import temoignageModule from "../modules/temoignage";
import versetModule from "../modules/verset";

import categorieModule from "../modules/categorie";
import profilModule from "../modules/profil";
import menuModule from "../modules/menu";
import actionModule from "../modules/action";

import NotFound from "../layouts/NotFound.vue";
import InternalError from "../layouts/InternalError.vue";

import { registerModules } from "../register-modules";

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

if (localStorage.getItem('token')) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`
}

export const routes = [
  {
    path: '/',
    name: 'home',
    redirect: {
      path: '/auth'
    }
  },
  {
    path: "/:catchAll(.*)",
    name: 'Not Found',
    component: NotFound
  },
  {
    path: "/500",
    name: 'Internal Error',
    component: InternalError
  }
]

/**
 * Registred Modules Apps
 */
registerModules({

  priere: priereModule,
  enseignement:   enseignementModule,
  temoignage: temoignageModule,
  verset: versetModule,

  dash: dashModule,
  categorie: categorieModule,
  user: userModule,
  menu: menuModule,
  profil: profilModule,
  action: actionModule,

  auth: authModule,
}, routes);

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched[0].path !== '/auth' && !localStorage.getItem('token')) next({ name: 'Login' })
  else if (to.matched[0].path == '/auth' && localStorage.getItem('token')) next({ name: 'Dashboard' })
  else next()
})

// Définissez l'intercepteur de réponse
axios.interceptors.response.use(
  response => response,
  error => {
    const status = error.response ? error.response.status : null;
    if (status === 403 || status === 401) {
      localStorage.removeItem('token');
      router.push('/auth');
    }
    return Promise.reject(error);
  }
);

export default router