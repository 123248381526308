export const SET_PROFILES = (state, profiles) => {
  state.profiles = profiles;
}

export const SET_PROFILE = (state, profile) => {
  state.profile = profile;
}

export const GET_PROFILE = (state, index) => {
  if (index) {
    return state.profiles.find(profile => profile.id === index);
  }
}
export const REMOVE_PROFILE = (state, index) => {
  let rs = state.profiles.filter(currentProfile => {
    return currentProfile.id !== index;
  });

  state.profiles = [...rs];
}