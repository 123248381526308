import axios from 'axios';
import UserService from "../../../services/user.service"

export const getUsers = async ({ commit }, params = null) => {
    let link = '/users'
    if (params != null)
        link += '?' + params
    await axios.get(link).then(response => {
            commit('SET_USERS', response.data.data);
        });
}

export const countUserConnectes = async ({ commit }, params = null) => {
    let link = '/users/all/connectes'
    if (params != null)
        link += '?' + params
    await axios.get(link).then(response => {
            commit('SET_USER_CONNECTES', response.data.data);
        });
}

export const countUserInscrits = async ({ commit }, params = null) => {
    let link = '/users/count/inscrits'
    if (params != null)
        link += '?' + params
    await axios.get(link).then(response => {
            commit('SET_USER_INSCRITS', response.data.data);
        });
}

export const getUser = async ({ commit }, id) => {
    axios.get('/users/' + id)
        .then(response => {
            commit('SET_USER', response.data.data);
        });
}


export const postUser = ({ commit }, user) => {
    return UserService.insertUser(user).then(
        response => {
            commit("SET_USER", user);
            return Promise.resolve(response);
        },
        error => {
            return Promise.reject(error);
        }
    );
}

export const postUserAll = ({ commit }, users) => {
    return UserService.insertUserAll(users).then(
        response => {
            commit("SET_ALL_USERS", users);
            return Promise.resolve(response);
        },
        error => {
            return Promise.reject(error);
        }
    );
}

export const putPass = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        axios.put(`users/update_password`, user)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_USER", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putUserInfo = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        axios.patch(`users`, user)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("SET_USER", data.data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putUser = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        axios.put(`users`, user)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_USER", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const deleteUser = ({ commit }, id) => {
    axios.defaults.headers.common['authentication'] = `auth ${localStorage.getItem('auth')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`users/${id}`)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("REMOVE_USER", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const activeUser = ({ commit }, id) => {
    axios.defaults.headers.common['authentication'] = `auth ${localStorage.getItem('auth')}`;
    return new Promise((resolve, reject) => {
        axios.put(`users/switch_activation_user/${id}`)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_USER", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}


export const profilUser = ({ commit }, id) => {
    axios.defaults.headers.common['authentication'] = `auth ${localStorage.getItem('auth')}`;
    return new Promise((resolve, reject) => {
        axios.put(`users/switch_profil_user/${id}`)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_USER", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}