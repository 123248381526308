<template>
    <div class="page">

        <!-- Main-error-wrapper -->
        <div class="main-error-wrapper  page page-h ">
            <img src="/assets/img/media/500.png" class="error-page" alt="error">
            <h2>Oopps. La page que vous cherchiez n’existe pas.</h2>
            <h6>Vous avez peut-être mal saisi l’adresse ou la page a peut-être été déplacée.</h6>
            <a class="btn btn-outline-danger" @click="$router.go(-1)" href="#">Retour à l'accueil</a>
        </div>
        <!-- /Main-error-wrapper -->

    </div>
</template>