import axios from 'axios';

export const getCategories = async ({ commit }, params = null) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    let link = '/categories'
    if (params != null)
        link += '?' + params
    await axios.get(link).then((response) => {
        commit('SET_CATEGORIES', response.data.data);
    })

}

export const postCategorieAll = ({ commit }, categories) => {
    return new Promise((resolve, reject) => {
        axios.post(`/categories`, categories)
            .then((response) => {
                const { data, status } = response.data;
                if (status === 200) {
                    if (Array.isArray(data.data))
                        commit("SET_ALL_CATEGORIES", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const getCategorie = async ({ commit }, id) => {
    axios.get('/categories/' + id)
        .then(response => {
            commit('SET_CATEGORIE', response.data.data);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postCategorie = ({ commit }, categorie) => {
    return new Promise((resolve, reject) => {
        axios.post(`/categories`, categorie)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_CATEGORIE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putCategorie = ({ commit }, categorie) => {
    return new Promise((resolve, reject) => {
        axios.put(`/categories/${categorie.id}`, categorie)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_CATEGORIE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const deleteCategorie = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/categories/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("REMOVE_CATEGORIE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}