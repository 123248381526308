export const SET_VERSETS = (state, versets) => {
  state.versets = versets;
}

export const SET_VERSET = (state, verset) => {
  state.verset = verset;
}

export const SET_ALL_VERSETS = (state, versets) => {
  state.versets.concat(versets);
}

export const GET_VERSET = (state, index) => {
  if (index) {
    return state.versets.find(verset => verset.id === index);
  }
}
export const REMOVE_VERSET = (state, index) => {
  let rs = state.versets.filter(currentVerset => {
    return currentVerset.id !== index;
  });

  state.versets = [...rs];
}