export const SET_CATEGORIES = (state, categories) => {
  state.categories = categories;
}

export const SET_CATEGORIE = (state, categorie) => {
  state.categorie = categorie;
}

export const SET_ALL_CATEGORIES = (state, categories) => {
  state.categories.concat(categories);
}

export const GET_CATEGORIE = (state, index) => {
  if (index) {
    return state.categories.find(categorie => categorie.id === index);
  }
}
export const REMOVE_CATEGORIE = (state, index) => {
  let rs = state.categories.filter(currentCategorie => {
    return currentCategorie.id !== index;
  });

  state.categories = [...rs];
}