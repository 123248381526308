import axios from 'axios';

export const getEnseignements = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('/enseignements').then((response) => {
        commit('SET_ENSEIGNEMENTS', response.data.data);
    })

}

export const postEnseignementAll = ({ commit }, enseignements) => {
    return new Promise((resolve, reject) => {
        axios.post(`/enseignements`, enseignements)
            .then((response) => {
                const { data, status } = response.data;
                if (status === 200) {
                    if (Array.isArray(data.data))
                        commit("SET_ALL_ENSEIGNEMENTS", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const getEnseignement = async ({ commit }, id) => {
    axios.get('/enseignements/' + id)
        .then(response => {
            commit('SET_ENSEIGNEMENT', response.data.data);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postEnseignement = ({ commit }, enseignement, config) => {
    return new Promise((resolve, reject) => {
        axios.post(`/enseignements`, enseignement, config)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_ENSEIGNEMENT", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putEnseignement = ({ commit }, enseignement) => {
    return new Promise((resolve, reject) => {
        axios.put(`/enseignements/${enseignement.id}`, enseignement)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_ENSEIGNEMENT", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const deleteEnseignement = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/enseignements/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("REMOVE_ENSEIGNEMENT", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}