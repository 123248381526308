import axios from 'axios';
// import authHeader from './auth-header';

class AuthService {
    login(user) {
        return axios
            .post('/auth/login', user)
            .then(response => {
                if (response.data.data.accessToken.accessToken) {
                    localStorage.setItem('token', response.data.data.accessToken.accessToken);
                }
                return response;
            })
            .catch(function (error) {
                return error
            });
    }

    async info() {
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
        return axios
            .get('/auth/me')
            .then(response => {
                return response.data;
            })
            .catch(function (error) {
                return error
            });
    }

    otp(user) {
        return axios
            .post('/auth/verify', user)
            .then(response => {
                return response.data;
            })
            .catch(function (error) {
                return error
            });
    }

    verify(user) {
        return axios
            .post('/auth/otp', user)
            .then(response => {
                return response.data;
            })
            .catch(function (error) {
                return error
            });
    }

    reset(user) {
        axios.defaults.headers.common['authentication'] = `auth ${localStorage.getItem('auth')}`;
        return axios
            .put('/auth/reset-password', user)
            .then(response => {
                return response.data;
            })
            .catch(function (error) {
                return error
            });
    }

    subscribe() {
        return axios
            .post('/user/app/generate', {
                "apikey": "P@ssword2"
            })
            .then(response => {
                return response.data;
            })
    }
}

export default new AuthService();
