export const categories = (state) => {
    return state.categories
}

export const categorieCount = (state, getters) => {
    return getters.doneCategories.length
}

export const getCategorieById = (state) => (id) => {
    return state.categories.find(categorie => categorie.id === id)
}

export const categorie = (state) => {
    return state.categorie
}