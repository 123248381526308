import { createRouter, createWebHistory } from 'vue-router'
import store from "./store";

const Module = () => import("../../layouts/Module.vue");
const Dash = () => import("./views/DashView.vue");
const Control = () => import("./views/ControlView.vue");


const routes = [{
  path: "/dash",
  component: Module,
  children: [
    {
      path: "",
      name: 'Dashboard',
      component: Dash
    },
    {
      path: "control",
      component: Control
    },
  ]
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default {
  store,
  router, routes
};
