import { createRouter, createWebHistory } from 'vue-router'
import store from "./store";

const Module = () => import("./Module.vue");
const Login = () => import("./views/LoginView.vue");
const Verif = () => import("./views/VerifView.vue");
const Otp = () => import("./views/OtpView.vue");
const Passe = () => import("./views/PasseView.vue");

const routes = [{
  path: "/auth",
  component: Module,
  children: [
    {
      path: "",
      name: 'Login',
      component: Login
    },
    {
      path: "verif",
      component: Verif
    },
    {
      path: "otp",
      component: Otp
    },
    {
      path: "passe",
      component: Passe
    },
  ]
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default {
  store,
  router, routes
};
