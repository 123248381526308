import axios from 'axios';
// import authHeader from './auth-header';

const API_URL = 'https://jsonplaceholder.typicode.com/users';

class UserService {
  getUsers() {
    return axios.get(API_URL);
  }

  getUser(id) {
    return axios.get(API_URL + '/users/' + id);
  }

  deleteUser(id) {
    return axios.delete(API_URL + '/' + id);
  }

  updateUser(id, user) {
    return axios
            .put('/users', user)
            .then(response => {
                return response.data;
            })
            .catch(function (error) {
                if (error.response) {
                    return error.response.data
                }
            });
  }

  insertUser(user) {
    return axios
      .post('/users', user)
      .then(response => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data
        }
      });
  }

  insertUserAll(users) {
    return axios
      .post('/users', users)
      .then(response => {
        return response.data;
      })
      .catch(function (error) {
        if (error.response) {
          return error.response.data
        }
      });
  }
}

export default new UserService();
