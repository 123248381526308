import axios from 'axios';

export const getTemoignages = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('/temoignages').then((response) => {
        commit('SET_TEMOIGNAGES', response.data.data);
    })

}

export const postTemoignageAll = ({ commit }, temoignages) => {
    return new Promise((resolve, reject) => {
        axios.post(`/temoignages`, temoignages)
            .then((response) => {
                const { data, status } = response.data;
                if (status === 200) {
                    if (Array.isArray(data.data))
                        commit("SET_ALL_TEMOIGNAGES", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    });
}

export const getTemoignage = async ({ commit }, id) => {
    axios.get('/temoignages/' + id)
        .then(response => {
            commit('SET_TEMOIGNAGE', response.data.data);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postTemoignage = ({ commit }, temoignage) => {
    return new Promise((resolve, reject) => {
        axios.post(`/temoignages`, temoignage)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_TEMOIGNAGE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putTemoignage = ({ commit }, temoignage) => {
    return new Promise((resolve, reject) => {
        axios.put(`/temoignages/${temoignage.id}`, temoignage)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_TEMOIGNAGE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const deleteTemoignage = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/temoignages/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("REMOVE_TEMOIGNAGE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}