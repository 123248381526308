export const SET_MENUS = (state, menus) => {
  state.menus = menus;
}

export const SET_MENU = (state, menu) => {
  state.menu = menu;
}

export const GET_MENU = (state, index) => {
  if (index) {
    return state.menus.find(menu => menu.id === index);
  }
}
export const REMOVE_MENU = (state, index) => {
  let rs = state.menus.filter(currentMenu => {
    return currentMenu.id !== index;
  });

  state.menus = [...rs];
}