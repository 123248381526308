export const ADD_AUTH = (state, auth) => {
  state.auth = auth;
}

export const REMOVE_AUTH= (state) => {
  state.auth = null;
}

export const ADD_PERMISSION = (state, permission) => {
  state.permission = permission;
}