import axios from 'axios';

export const getProfiles = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('/profils').then((response) => {
        commit('SET_PROFILES', response.data.data);
    }).catch((error) => {
        if (error.response.status == 403) {
            localStorage.removeItem('token')
            localStorage.removeItem('auth')
        }
    })

}

export const getProfile = async ({ commit }, id) => {
    axios.get('/profils/' + id)
        .then(response => {
            commit('SET_PROFILE', response.data.data[0]);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postProfile = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        axios.post(`/profils`, user)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_PROFILE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const putProfile = ({ commit }, user) => {
    return new Promise((resolve, reject) => {
        axios.put(`/profils`, user)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_PROFILE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}

export const deleteProfile = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/profils/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 204) {
                    commit("REMOVE_PROFILE", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}