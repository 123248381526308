<template>
    <div class="page">
		
        <!-- Main-error-wrapper -->
        <div class="main-error-wrapper page page-h">
            <img src="/assets/img/media/404.png" class="error-page" alt="error">
            <h2>Oups. La page que vous cherchiez n’existe pas.</h2>
            <h6>Vous avez peut-être mal saisi l’adresse ou la page a peut-être été déplacée.</h6>
            <router-link class="btn btn-outline-danger" to="/" >Retour à l'accueil</router-link>
        </div>
        <!-- /Main-error-wrapper -->
        
    </div>
</template>