import { createRouter, createWebHistory } from 'vue-router'
import store from "./store";

const Module = () => import("../../layouts/Module.vue");
const Add = () => import("./views/AddView.vue");
const List = () => import("./views/ListView.vue");
const Edit = () => import("./views/EditView.vue");
const Show = () => import("./views/ShowView.vue");

const routes = [{
  path: "/temoignages",
  component: Module,
  children: [
    {
      path: "",
      component: List
    },
    {
      path: "add",
      component: Add
    },
    {
      path: "edit/:id",
      component: Edit
    },
    {
      path: "show/:id",
      component: Show
    },
  ]
}];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default {
  store,
  router, routes
};
