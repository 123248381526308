export const versets = (state) => {
    return state.versets
}

export const versetCount = (state, getters) => {
    return getters.doneVersets.length
}

export const getVersetById = (state) => (id) => {
    return state.versets.find(verset => verset.id === id)
}

export const verset = (state) => {
    return state.verset
}