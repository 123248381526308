import axios from 'axios';

export const getBilanGlobal = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('user/dashs/getbilan').then((response) => {
        commit('SET_BILAN', response.data.data);
    })
}

export const getBilan = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('user/dashs/control').then((response) => {
        commit('SET_BILAN', response.data.data);
    })
}

export const getControl = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('user/dashs/patients').then((response) => {
        commit('SET_PATIENTS', response.data.data);
    })
}

export const postControl = ({ commit }, control) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve) => {
        axios.post(`user/dashs`, control)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("SET_PATIENTS", data);
                    resolve({ data, status })
                }
            })
    })
}