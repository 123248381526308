export const profiles = (state) => {
    return state.profiles
}

export const profileCount = (state, getters) => {
    return getters.doneProfiles.length
}

export const getProfileById = (state) => (id) => {
    return state.profiles.find(profile => profile._id === id)
}

export const profile = (state) => {
    return state.profile
}