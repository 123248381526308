import axios from 'axios';

export const getMenus = async ({ commit }) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    await axios.get('/menus').then((response) => {
        commit('SET_MENUS', response.data.data);
    })

}

export const getMenu = async ({ commit }, id) => {
    axios.get('/menus/' + id)
        .then(response => {
            commit('SET_MENU', response.data.data[0]);
        })
        .catch(error => {
            if (error.response.status == 403) {
                localStorage.removeItem('token')
                localStorage.removeItem('auth')
            }
        });
}

export const postMenu = ({ commit }, menu) => {
    return new Promise((resolve,reject) => {
        axios.post(`/menus`, menu)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("ADD_MENU", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                 reject(error)
            });
    })
}

export const putMenu = ({ commit }, menu) => {
    return new Promise((resolve, reject) => {
        axios.put(`/menus`, menu)
            .then((response) => {
                const { data, status } = response;
                if (status === 200) {
                    commit("UPDATE_MENU", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                 reject(error)
            });
    })
}

export const deleteMenu = ({ commit }, id) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
    return new Promise((resolve, reject) => {
        axios.delete(`/menus/` + id)
            .then((response) => {
                const { data, status } = response;
                if (status === 204) {
                    commit("REMOVE_MENU", data);
                    resolve({ data, status })
                }
            })
            .catch((error) => {
                reject(error)
            });
    })
}