export const enseignements = (state) => {
    return state.enseignements
}

export const enseignementCount = (state, getters) => {
    return getters.doneEnseignements.length
}

export const getEnseignementById = (state) => (id) => {
    return state.enseignements.find(enseignement => enseignement._id === id)
}

export const enseignement = (state) => {
    return state.enseignement
}