export const temoignages = (state) => {
    return state.temoignages
}

export const temoignageCount = (state, getters) => {
    return getters.doneTemoignages.length
}

export const getTemoignageById = (state) => (id) => {
    return state.temoignages.find(temoignage => temoignage._id === id)
}

export const temoignage = (state) => {
    return state.temoignage
}