export const SET_ENSEIGNEMENTS = (state, enseignements) => {
  state.enseignements = enseignements;
}

export const SET_ENSEIGNEMENT = (state, enseignement) => {
  state.enseignement = enseignement;
}

export const SET_ALL_ENSEIGNEMENTS = (state, enseignements) => {
  state.enseignements.concat(enseignements);
}

export const GET_ENSEIGNEMENT = (state, index) => {
  if (index) {
    return state.enseignements.find(enseignement => enseignement.id === index);
  }
}
export const REMOVE_ENSEIGNEMENT = (state, index) => {
  let rs = state.enseignements.filter(currentEnseignement => {
    return currentEnseignement.id !== index;
  });

  state.enseignements = [...rs];
}