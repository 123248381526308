export const SET_USERS = (state, users) => {
  state.users = users;
}

export const SET_USER_CONNECTES = (state, userConnectes) => {
  state.userConnectes = userConnectes;
}

export const SET_USER_INSCRITS = (state, userInscrits) => {
  state.userInscrits = userInscrits;
}

export const SET_ALL_USERS = (state, users) => {
  state.users.concat(users);
}

export const SET_USER = (state, user) => {
  state.user = user;
}

export const GET_USER = (state, index) => {
  if (index) {
    return state.users.find(user => user.id === index);
  }
}

export const REMOVE_USER = (state, index) => {
  let rs = state.users.filter(currentUser => {
    return currentUser.id !== index;
  });

  state.users = [...rs];
}