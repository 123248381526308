export const actions = (state) => {
    return state.actions
}

export const actionCount = (state, getters) => {
    return getters.doneActions.length
}

export const getActionById = (state) => (id) => {
    return state.actions.find(action => action._id === id)
}

export const action = (state) => {
    return state.action
}