export const SET_PRIERES = (state, prieres) => {
  state.prieres = prieres;
}

export const SET_PRIERE = (state, priere) => {
  state.priere = priere;
}

export const SET_ALL_PRIERES = (state, prieres) => {
  state.prieres.concat(prieres);
}

export const GET_PRIERE = (state, index) => {
  if (index) {
    return state.prieres.find(priere => priere.id === index);
  }
}
export const REMOVE_PRIERE = (state, index) => {
  let rs = state.prieres.filter(currentMedecin => {
    return currentMedecin.id !== index;
  });

  state.prieres = [...rs];
}