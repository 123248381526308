import state from "./state"
import * as getters from "./getters";
import * as mutations from "./mutations";
import AuthService from "../../../services/auth.service"
import axios from "axios";


export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions: {
    login({ commit }, user) {
      return new Promise((resolve, reject) => {
        return axios
          .post('/auth/login', user)
          .then(response => {

            if (response.status==200) {
              commit("ADD_AUTH", response?.data.user);
              if (response.data.access_token) {
                localStorage.setItem('token', response.data.access_token);
              }
            }

            return resolve(response);
          })
          .catch(function (error) {
            return reject(error);
          });
      })
    },

    async info({ commit }) {
      return AuthService.info().then(
        response => {
          commit("ADD_AUTH", response.data);
          return Promise.resolve(response.data);
        },
        error => {
          commit("REMOVE_AUTH");
          return Promise.reject(error);
        }
      );
    },

    verify({ commit }, user) {
      return new Promise((resolve, reject) => {
        return axios
          .post('/auth/verify', user)
          .then(response => {
            commit("ADD_AUTH", user);
            return resolve(response.data);
          })
          .catch(function (error) {
            return reject(error);
          });
      })
    },

    otp({ commit }, user) {
      return new Promise((resolve, reject) => {
        return axios
          .post('/auth/otp', user)
          .then(response => {
            commit("ADD_AUTH", user);
            return resolve(response.data);
          })
          .catch(function (error) {
            return reject(error);
          });
      })
    },

    reset({ commit }, user) {

      axios.defaults.headers.common['authentication'] = `auth ${localStorage.getItem('auth')}`;

      return new Promise((resolve, reject) => {
        return axios
          .put('/auth/reset-password', user)
          .then(response => {
            commit("ADD_AUTH", user);
            return resolve(response.data);
          })
          .catch(function (error) {
            return reject(error);
          });
      })
    }

  }
};
